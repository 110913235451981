:root {
  --font-family: Lato,sans-serif
}

.p-component,
.p-link,
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input,
.p-chips .p-chips-multiple-container .p-chips-input-token input,
.p-inputtext,
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left,
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right,
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close,
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left,
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right,
.p-terminal .p-terminal-input,
.p-breadcrumb .p-breadcrumb-chevron
{
  font-family: Lato,sans-serif ;
}

.p-button:enabled:hover{
  background: none;
  color: inherit;
  border: none;
}

.p-button:focus{
  border: none;
  box-shadow: none
}

body.p-overflow-hidden {
  overflow: hidden;
}